/* HEADER  */

.App {
  text-align: center;
}

.logo {
  max-height: 40px;
}

.navitems {
  /* font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 1;
  white-space: normal;
}

.header-button {
  border: 3px solid #c8c8c8;
  font-size: 1rem;
  text-align: center;
}

.sliderContent {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  align-items: center;
  transform: translateY(-60%);
}

.sliderContent h3 {
  color: white;
}

.sliderImage::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .7;
}

.sliderImage {
  position: relative;
}

/*  Technical page */

.accordion-button {
  font-size: 1.5rem;
}

.accordion-body {
  font-size: 1.25rem;
}


/*  MISC */
/* bigger clickable area */
.block {
  display: block;
}

/* p {
  font-size: 1rem;
} */

/* FOOTER  */

.footer-child {
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2%;
}

.footer-child div {
  flex-basis: 32%;
  text-align: center;
}

.footer-child>div:hover {
  opacity: 70%;
}

/* LARGER SCREENS */

@media(min-width:1240px) {
  .nav-link {
    font-size: 1rem;
  }

  .header-button {
    font-size: 1rem;

  }
}


/* LAPTOP */
@media(min-width:992px) {
  .navitems {
    font-size: 0.85rem;
  }

  .header-button {
    font-size: 1rem;

  }
}


/* TABLET */
@media(min-width: 767px) {
  .header-button {
    text-align: center;
  }
}