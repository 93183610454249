.container-slider {
  max-width: auto;
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.slide {
  width: 100%;
  height: 100vh;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
  filter: brightness(80%);
}

.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}

.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}

.dot.active {
  background: rgb(32, 32, 32);
}

@media screen and (max-width: 768px) {
  .btn-slide {
    display: none;
  }
}