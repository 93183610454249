/*!
// Contents
// ------------------------------------------------

 1. Global Styles
 2. Mixins
 3. Typography
 4. Colours
 5. Sections
 6. Buttons
 7. Nav
 8. Tooltips
 9. Alerts
 10. Icons
 11. Forms
 12. Accordions
 13. Tabs
 14. Progress Bars
 15. Stats
 16. Breadcrumbs
 17. Pagination
 18. Icon Features
 19. Pricing Tables
 20. Page Titles
 21. Widgets
 22. Image Tiles
 23. Sliders
 24. Galleries
 25. Intros
 26. Shop
 27. Video
 28. Blog
 29. Image Blocks
 30. Portfolio
 31. Testimonials
 32. Countdown
 33. Maps
 34. Twitter
 35. Footers
 36. Spacing

/*!---------- 1. GLOBAL STYLES ----------*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  line-height: 24px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #666;
  overflow-x: hidden;
}
body.boxed-layout {
  background: #eee;
}
body.boxed-layout .main-container {
  background: #fff;
}
body.boxed-layout,
.boxed-layout .nav-container,
.boxed-layout .main-container,
.boxed-layout nav {
  max-width: 1366px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
ul {
  list-style: none;
}
ul.bullets {
  list-style: inside;
}
.main-container {
  clear: both;
}
hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 0 24px 0;
  width: 100%;
}
hr.short-thick {
  max-width: 50px;
  border-top: 5px solid #ccc;
  opacity: 1 !important;
}
.image-bg hr {
  border-color: #fff;
  opacity: 0.6;
}
.image-bg.bg-light hr {
  border-color: #ccc;
  opacity: 1;
}
.bg-dark hr {
  border-color: #555;
}
.inline-block {
  display: inline-block;
}
.list-inline {
  margin-left: 0;
}
.list-inline > li {
  padding: 0 8px;
}
.list-inline > li:last-child {
  padding-right: 0;
}
.list-inline > li:first-child {
  padding-left: 0;
}
.bg-primary .list-inline i {
  color: #f0aa87;
}
.overflow-hidden {
  overflow: hidden;
}
.display-block {
  display: block;
}
.show-grid {
  border: 1px dashed rgba(255, 255, 255, 0);
  padding: 8px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  cursor: default;
}
.show-grid:hover {
  border-color: #222;
}
.right {
  right: 0;
}
.relative {
  position: relative;
  z-index: 2;
}
.clearboth {
  clear: both;
}
.spread-children * {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}
.spread-children-large * {
  display: inline-block;
  margin-left: 24px;
  margin-right: 24px;
}
.container {
  position: relative;
}
.vnu {
  display: inline;
}
.row-gapless > div[class*="col-"] {
  padding: 0;
}
@media all and (max-width: 1100px) {
  .col-md-push-1 {
    left: 0;
  }
}
@media all and (max-width: 768px) {
  .pull-left-sm {
    float: left !important;
  }
  .overflow-hidden-xs {
    overflow: hidden;
  }
}
@media all and (max-width: 767px) {
  .spread-children * {
    margin-left: 6px;
    margin-right: 6px;
  }
}
/*!---------- 2. MIXINS ----------*/
.v-align-children {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}
.v-align-children-column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media all and (max-width: 990px) {
  .v-align-children {
    display: block !important;
  }
}
.v-align-transform {
  position: relative;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  top: 50%;
  z-index: 2;
}
.align-bottom {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;
}
.align-top {
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;
}
/*!---------- 3. TYPOGRAPHY ----------*/
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center !important;
  }
}
.bold {
  font-weight: 600 !important;
}
.thin {
  font-weight: 100 !important;
}
@media all and (max-width: 767px) {
  .thin {
    font-weight: 300 !important;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre,
table,
blockquote,
input,
button,
select,
textarea {
  margin-bottom: 24px;
  margin-top: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: #292929;
}
h1,
.h1 {
  font-size: 56px;
  line-height: 64px;
}
h1.large {
  font-size: 72px;
  line-height: 80px;
  font-weight: 100;
}
@media all and (max-width: 990px) {
  h1.large {
    font-size: 56px;
    line-height: 64px;
  }
}
h2 {
  font-size: 40px;
  line-height: 48px;
}
h3 {
  font-size: 32px;
  line-height: 40px;
}
h4 {
  font-size: 24px;
  line-height: 32px;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
h6,
.h6 {
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
}
@media all and (max-width: 767px) {
  h1,
  h1.large,
  .h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-size: 18px;
    line-height: 26px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  h6,
  .h6 {
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
  }
}
.uppercase {
  font-weight: 400;
  text-transform: uppercase;
}
h1.uppercase {
  letter-spacing: 17px;
  margin-right: -17px;
}
h2.uppercase {
  letter-spacing: 10px;
  margin-right: -10px;
}
h3.uppercase {
  letter-spacing: 6px;
  margin-right: -6px;
}
h4.uppercase {
  letter-spacing: 3px;
  margin-right: -3px;
}
h5.uppercase {
  letter-spacing: 2px;
  margin-right: -2px;
}
h6.uppercase,
.h6-u {
  letter-spacing: 2px;
  font-weight: 700;
}
.bold-h6 {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
p,
span {
  font-weight: 400;
}
p.lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.sub {
  font-size: 12px;
}
@media all and (max-width: 767px) {
  p.lead {
    font-size: 13px;
    line-height: 24px;
  }
  p {
    font-size: 12px;
  }
}
a,
a:visited,
a:focus,
a:active,
a:hover {
  text-decoration: none;
  outline: none;
}
a {
  font-weight: 600;
  color: #e5692c;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  cursor: poitner;
}
.image-bg a,
.bg-primary a {
  color: #fff;
}
.image-bg a:hover,
.bg-primary a:hover {
  color: #fff;
  opacity: 0.9;
}
.bg-light a {
  color: #e5692c;
}
.bg-light a:hover {
  color: #e5692c;
  opacity: 1;
}
a:hover {
  color: #c65118;
  text-decoration: none;
}
.label {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  letter-spacing: 1px;
  background: #e5692c;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 10px;
  vertical-align: middle;
}
blockquote {
  overflow: hidden;
  font-family: "Merriweather", "Georgia", Times New Roman, Times, serif;
  font-size: 20px;
  line-height: 40px;
  font-style: italic;
  background: #f5f5f5;
  padding: 32px;
  color: #777;
  font-weight: 300;
}
.bg-secondary blockquote {
  background: #fff;
  border-color: #e5692c;
}
blockquote .author {
  font-size: 12px;
  display: block;
  float: right;
  margin-top: 16px;
}
.number {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.columns-2 {
  column-count: 2;
  -webkit-column-count: 2;
}
@media all and (max-width: 767px) {
  br {
    display: none;
  }
  blockquote {
    font-size: 16px;
    line-height: 32px;
  }
  .columns-2 {
    column-count: 1;
    -webkit-column-count: 1;
  }
}
.text-left {
  text-align: left !important;
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left !important;
  }
}
/*!---------- 3.1. TYPOGRAPHY - LISTS ----------*/
ul[data-bullet] li {
  line-height: 32px;
}
ul[data-bullet] li i:first-child {
  margin-right: 16px;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  display: inline-block;
}
ul.lead li {
  font-size: 16px;
  line-height: 40px;
}
/*!---------- 4. COLOURS ----------*/
.bg-primary {
  background: #e5692c !important;
}
.bg-secondary {
  background: #f8f8f8;
}
.bg-white {
  background: #fff;
}
.bg-dark {
  background: #292929;
}
.color-body {
  color: #666;
}
.color-primary {
  color: #e5692c !important;
}
.color-red {
  color: #e31d3b !important;
}
.color-white {
  color: #fff;
}
/*!---------- 5. SECTIONS ----------*/
section,
footer {
  padding: 96px 0;
  position: relative;
  overflow: hidden;
}
footer {
  padding: 72px 0;
}
.fullscreen {
  height: 100vh;
}
.bg-dark h1,
nav.bg-dark h1,
footer.bg-dark h1,
.bg-primary h1,
.bg-dark h2,
nav.bg-dark h2,
footer.bg-dark h2,
.bg-primary h2,
.bg-dark h3,
nav.bg-dark h3,
footer.bg-dark h3,
.bg-primary h3,
.bg-dark h4,
nav.bg-dark h4,
footer.bg-dark h4,
.bg-primary h4,
.bg-dark h5,
nav.bg-dark h5,
footer.bg-dark h5,
.bg-primary h5,
.bg-dark h6,
nav.bg-dark h6,
footer.bg-dark h6,
.bg-primary h6 {
  color: #ffffff;
}
.bg-dark p,
nav.bg-dark p,
footer.bg-dark p,
.bg-primary p,
.bg-dark span,
nav.bg-dark span,
footer.bg-dark span,
.bg-primary span,
.bg-dark li,
nav.bg-dark li,
footer.bg-dark li,
.bg-primary li {
  color: #fefefe;
}
@media all and (max-width: 767px) {
  section {
    padding: 80px 0;
  }
}
.overlay:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #292929;
  opacity: 0.3;
  z-index: 2;
}
.overlay-heavy:before {
  opacity: 0.6;
}
.bg-light.overlay:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  opacity: 0.1;
  z-index: 2;
}
.image-bg h1,
.image-bg h2,
.image-bg h3,
.image-bg h4,
.image-bg h5,
.image-bg h6 {
  color: #ffffff;
}
.image-bg p,
.image-bg span,
.image-bg li {
  color: #ffffff;
}
.image-bg .container,
.image-bg div[class*="col-"] {
  position: relative;
  z-index: 3;
}
.container.image-bg .row {
  position: relative;
  z-index: 3;
}
.background-image-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: #292929;
  background-size: cover !important;
  background-position: 50% 50% !important;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
}
.background-image-holder img {
  display: none;
}
.background-multiply .background-image-holder {
  background-color: #e5692c !important;
  background-blend-mode: multiply;
}
.background-image-holder.fadeIn {
  opacity: 1;
}
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100vh;
  top: -50vh;
  transition: all 0s ease !important;
  transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 767px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
  }
}
/*!---------- 6. BUTTONS ----------*/
.btn {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 2px solid #e5692c;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  color: #e5692c;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  margin-right: 8px;
  margin-bottom: 24px;
}
.btn:last-child,
.btn:last-of-type {
  margin-right: 0;
}
.btn:hover {
  background: #e5692c;
  color: #fff;
}
.btn-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  min-width: 0;
  padding: 0;
  line-height: 38px;
}
.btn-lg {
  height: 50px;
  line-height: 46px;
  min-width: 200px;
}
.btn-icon.btn-lg {
  width: 50px;
  height: 50px;
  line-height: 49px;
  font-size: 24px;
  min-width: 0;
}
.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
  line-height: 29px;
  font-size: 13px;
  min-width: 0;
  padding: 0 0 0 1px !important;
}
.btn-sm {
  height: 30px;
  font-size: 11px;
  line-height: 27px;
  min-width: 0;
}
.btn-filled {
  background: #e5692c;
  color: #fff;
}
.btn-white,
.image-bg .btn,
.image-bg .btn:visited {
  color: #fff;
  border-color: #fff;
}
.btn-white:hover,
.image-bg .btn:hover,
.image-bg .btn:visited:hover {
  background: #fff;
  color: #222;
}
.image-bg .btn.btn-filled,
.image-bg .btn-filled:visited {
  border-color: #e5692c;
}
.image-bg .btn-filled:hover {
  border-color: #fff;
}
.btn-rounded {
  border-radius: 25px;
}
body.btn-rounded .btn {
  border-radius: 25px !important;
}
.bg-light .btn {
  border-color: #222;
  color: #222;
}
.bg-light .btn:visited,
.bg-light .btn:visited:hover {
  color: #222;
}
.bg-light .btn-filled,
.bg-light .btn-filled:visited {
  color: #fff;
}
.btn:visited {
  color: #e5692c;
}
.btn-white:visited,
.btn:visited:hover {
  color: #fff;
}
.btn-white:visited:hover {
  color: #222;
}
.btn-filled:visited {
  color: #fff;
}
.btn.bg-dark {
  color: #fff;
  border-color: #292929;
}
.btn.bg-dark:hover {
  background: #434343;
}
.bg-primary .btn {
  color: #fff;
  border-color: #fff;
}
.bg-primary .btn:hover {
  background: #fff;
  color: #e5692c;
}
/*!---------- 7. NAVIGATION ----------*/
.nav-container {
  -webkit-backface-visibility: hidden;
  max-width: 100%;
}
nav {
  background: #fff;
  -webkit-backface-visibility: hidden;
  max-width: 100%;
}
nav ul {
  margin-bottom: 0;
}
.module {
  display: inline-block;
  padding: 0 32px;
}
.module-group {
  display: inline-block;
}
.module.left,
.module-group.left {
  float: left;
}
.module.right,
.module-group.right {
  float: right;
}
nav .btn,
.nav-bar .btn {
  margin: 0;
  height: auto;
}
.nav-utility {
  height: 45px;
  line-height: 43px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}
.nav-utility i {
  position: relative;
  top: 1px;
}
.nav-bar {
  height: 55px;
  max-height: 55px;
  line-height: 53px;
  border-bottom: 1px solid #ccc;
}
.nav-bar .module,
.nav-bar .module-group {
  height: 55px;
}
.nav-bar a {
  display: inline-block;
  height: 55px;
}
.logo {
  max-height: 60%;
}
.logo-light {
  display: none;
}
nav.nav-centered .logo {
  max-height: 64px;
  margin: 64px 0 40px 0;
}
nav.bg-dark .logo-light {
  display: inline;
}
nav.bg-dark .logo-dark {
  display: none;
}
.has-dropdown {
  padding-right: 18px;
}
.has-dropdown:after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  content: "\e64b";
  font-family: "themify";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}
.menu {
  width: 100%;
  height: 55px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.menu.inline-block {
  width: auto;
}
.menu li a {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #292929;
  opacity: 0.5;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  max-width: 100%;
  white-space: normal;
}
.menu li a:hover {
  opacity: 1 !important;
}
.menu > li {
  margin-right: 32px;
  float: left;
  position: relative;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
}
.menu > li:last-child {
  margin-right: 0;
}
.menu > li:last-child > ul {
  right: 0;
}
.menu > li:last-child > ul ul {
  left: auto;
  right: 100%;
}
.menu > li ul {
  width: 200px;
  padding: 8px 0;
  background: #292929;
  position: absolute;
  z-index: 99;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transform: translate3d(0, 10px, 0);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  visibility: hidden;
  margin-top: -1px;
}
.menu > li > ul > li {
  position: relative;
  line-height: 24px;
  width: 100%;
  vertical-align: top;
}
.menu > li > ul > li i {
  display: inline-block;
  margin-right: 2px;
}
.menu > li > ul > .has-dropdown:after {
  color: #fff;
  top: 5px;
  right: 24px;
  content: "\e649";
}
.menu > li > ul li a {
  color: #fff;
  height: auto;
  padding: 6px 24px;
}
.menu > li > ul > li > ul {
  left: 100%;
  top: 0;
}
.menu > li:hover > ul {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  visibility: visible;
}
.menu > li > ul > li:hover > ul,
.has-dropdown:hover .mega-menu ul {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  visibility: visible;
}
.mega-menu {
  width: auto !important;
  white-space: nowrap;
  line-height: 24px;
}
.mega-menu ul {
  position: relative !important;
  left: auto !important;
  padding: 0 !important;
}
.mega-menu > li {
  width: 200px !important;
  overflow: hidden;
  display: inline-block;
}
.mega-menu .title {
  letter-spacing: 1px;
  color: #fff;
  display: inline-block;
  padding: 6px 24px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
.make-right {
  right: 0;
}
.module.widget-handle {
  border-left: 1px solid #ccc;
  padding: 0 24px;
  cursor: pointer;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
}
nav.nav-centered .module.widget-handle {
  border: none !important;
}
@media all and (max-width: 1100px) {
  .module.widget-handle {
    padding: 0 16px;
  }
}
.module.widget-handle i {
  font-size: 20px;
  line-height: 53px;
  opacity: 0.5;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.module.widget-handle:hover i,
.module.active i {
  opacity: 1;
}
.widget-handle .function {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: default;
  width: 200px;
  background: #292929;
  position: absolute;
  z-index: 99;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transform: translate3d(0, 10px, 0);
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  visibility: hidden;
  margin-top: -2px;
  right: 0;
}
.module.widget-handle:hover .function {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  visibility: visible;
}
.module.widget-handle .title {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  display: none;
  opacity: 0.5;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.module.widget-handle .title:hover {
  opacity: 1;
}
.widget-handle .cart {
  position: relative;
}
.widget-handle .cart .label {
  width: 17px;
  height: 17px;
  font-size: 10px;
  line-height: 17px;
  padding: 0;
  text-align: center;
  position: absolute;
  background: #e5692c;
  top: 10px;
  right: -10px;
  border-radius: 50%;
}
.widget-handle .search-form {
  padding: 8px;
  display: inline-block;
  width: 100%;
  line-height: 50px;
}
.widget-handle .search-form input {
  margin: 0;
  font-size: 16px;
}
nav .widget {
  margin: 0;
  padding: 24px;
}
nav .widget .title {
  display: none !important;
}
nav .widget,
nav .widget a:not(.btn) {
  color: #fff;
}
nav .widget hr {
  border-color: #777;
  margin-bottom: 16px;
}
nav .widget hr:first-of-type {
  display: none;
}
.cart-widget-handle .function {
  width: auto;
  background: #fff;
}
.cart-widget-handle .function span {
  color: #222 !important;
}
nav .cart-overview {
  min-width: 300px;
  margin-bottom: 16px;
}
nav .cart-overview a {
  height: auto;
}
.language .menu > li ul {
  max-width: 150px;
}
.nav-open {
  max-height: 10000px !important;
  height: auto !important;
}
.nav-open .mobile-toggle {
  border-bottom: 1px solid #ccc;
}
@media all and (max-width: 1120px) {
  .menu > li {
    margin-right: 24px;
  }
}
.has-offscreen-nav .main-container {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}
.offscreen-container {
  position: fixed;
  transform: translate3d(200%, 0, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  -moz-transform: translate3d(200%, 0, 0);
  width: 50%;
  top: 0;
  height: 100%;
  min-height: 100vh;
  z-index: 20;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  overflow: hidden;
}
.offscreen-container.reveal-nav {
  transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
}
.main-container.reveal-nav {
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
}
.offscreen-left .offscreen-container {
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
}
.offscreen-left .offscreen-container.reveal-nav {
  transform: translate3d(0%, 0, 0);
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
}
.offscreen-left .main-container.reveal-nav,
.offscreen-left nav.reveal-nav {
  transform: translate3d(50%, 0, 0) !important;
  -webkit-transform: translate3d(50%, 0, 0) !important;
  -moz-transform: translate3d(50%, 0, 0) !important;
}
.offscreen-container .close-nav {
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 24;
  font-size: 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.5;
}
.offscreen-container .close-nav:hover {
  opacity: 1;
}
.offscreen-container.bg-dark .close-nav i {
  color: #fff;
}
@media all and (max-width: 990px) {
  .offscreen-container {
    width: 100vw;
  }
  .offscreen-container.reveal-nav {
    transform: translate3d(0vw, 0, 0);
    -webkit-transform: translate3d(0vw, 0, 0);
    -moz-transform: translate3d(0vw, 0, 0);
  }
  .main-container.reveal-nav {
    transform: none !important;
  }
}
@media all and (max-width: 990px) {
  nav.fixed {
    position: absolute !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  nav.outOfSight {
    transform: translate3d(0, 0px, 0) !important;
    -webkit-transform: translate3d(0, 0px, 0) !important;
    -moz-transform: translate3d(0, 0px, 0) !important;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }
  .nav-bar,
  .nav-bar .module-group,
  .nav-bar .module {
    height: auto;
    overflow: hidden;
  }
  .nav-bar .module {
    padding: 0 16px;
  }
  .nav-bar .module-group {
    width: 100%;
    padding: 16px 0;
  }
  .nav-bar .module-group .module {
    display: block;
    float: none;
    width: 100%;
  }
  nav.nav-centered .logo {
    margin: 24px 0 16px 0;
    max-height: 40px;
  }
  .menu {
    height: auto;
  }
  .menu.inline-block {
    width: 100%;
  }
  .menu a {
    height: auto;
    line-height: 24px;
    padding: 4px 0;
  }
  .menu li {
    line-height: 24px;
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .menu > li ul {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0px, 0);
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
    left: 0;
  }
  .menu > li > ul {
    position: relative;
    opacity: 1;
    visibility: visible;
    display: none;
    transform: translate3d(0, 0px, 0);
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
  }
  .menu > li > ul > .has-dropdown:after {
    content: "\e64b";
  }
  .menu > li > ul > li > ul {
    left: 0;
    display: none;
    padding: 0;
  }
  .menu > li > ul li a,
  .mega-menu .title {
    padding: 4px 16px;
  }
  .has-dropdown .has-dropdown li {
    padding-left: 18px;
  }
  .has-dropdown {
    padding-right: 0;
  }
  .mega-menu {
    margin-left: 0 !important;
  }
  .mega-menu li {
    width: 100% !important;
  }
  .toggle-sub > ul,
  .toggle-sub .mega-menu ul {
    display: block !important;
  }
  .module.widget-handle {
    border-left: none;
    border-top: 1px solid #ccc;
    line-height: 40px;
    min-height: 40px;
  }
  .module.widget-handle .menu {
    line-height: 40px;
  }
  .module.widget-handle .menu li {
    line-height: 40px;
  }
  .module.widget-handle i {
    line-height: 40px;
  }
  .module.widget-handle .title {
    display: inline-block;
    position: relative;
    bottom: 3px;
    margin-left: 8px;
  }
  .widget-handle .function {
    width: 100%;
    position: relative;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
    visibility: visible;
    margin-top: 0;
    display: none;
    box-shadow: none !important;
  }
  .toggle-widget-handle .function {
    display: block !important;
  }
  .widget-handle .cart .label {
    width: 22px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    top: 18px;
  }
  .mobile-toggle {
    border-left: 1px solid #ccc !important;
    border-bottom: none !important;
  }
  .mobile-toggle i {
    line-height: 53px !important;
  }
  .mobile-toggle.absolute-xs {
    position: absolute;
    top: 0;
  }
  nav.nav-centered .mobile-toggle {
    width: 100%;
  }
  nav.bg-dark .module.widget-handle {
    border-top: 1px solid #444;
  }
  nav.bg-dark .mobile-toggle {
    border-left: 1px solid #444 !important;
  }
  nav.bg-dark .nav-open .mobile-toggle {
    border-bottom: 1px solid #444;
  }
}
nav.outOfSight {
  transform: translate3d(0, -200px, 0);
  -webkit-transform: translate3d(0, -200px, 0);
  -moz-transform: translate3d(0, -200px, 0);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
nav.scrolled {
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
}
nav.fixed,
nav.absolute {
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
}
nav.absolute {
  position: absolute;
}
nav.fixed {
  position: fixed;
  visibility: hidden;
  opacity: 0;
}
nav.fixed .nav-utility {
  display: none;
}
nav.fixed.scrolled {
  visibility: visible;
  opacity: 1;
}
nav.transparent .menu > li > a,
nav.transparent .module.widget-handle i,
nav.transparent .nav-utility {
  color: #fff;
}
nav.transparent .nav-utility,
nav.transparent .nav-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
nav.transparent .module.widget-handle {
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}
nav.transparent .menu > li > a,
nav.transparent .module.widget-handle i {
  opacity: 1;
}
nav.transparent .has-dropdown:after {
  color: #fff;
}
nav.transparent .logo-dark {
  display: none;
}
nav.transparent .logo-light {
  display: inline;
}
nav.transparent {
  background: none;
}
.has-offscreen-nav > nav.fixed {
  opacity: 1;
  transform: none;
  visibility: visible;
}
@media all and (max-width: 990px) {
  nav.transparent .nav-open {
    background: #fff;
  }
  nav.transparent .nav-open .menu > li > a,
  nav.transparent .nav-open .module.widget-handle i,
  nav.transparent .nav-open .nav-utility {
    color: #292929;
  }
  nav.transparent .nav-open .logo-dark {
    display: inline;
  }
  nav.transparent .nav-open .logo-light {
    display: none;
  }
  nav.transparent .nav-open .has-dropdown:after {
    color: #292929;
  }
  .menu > li:last-child > ul ul {
    right: 0;
  }
}
nav.transparent.fixed {
  background: #fff;
}
nav.transparent.fixed .logo-light {
  display: none;
}
nav.transparent.fixed .logo-dark {
  display: inline;
}
nav.transparent.fixed .menu > li > a,
nav.transparent.fixed .module.widget-handle i {
  color: #292929;
}
nav.transparent.fixed .menu > .has-dropdown:after {
  color: #fff;
  color: #292929;
}
nav.transparent.fixed .module.widget-handle {
  border-left: 1px solid #ccc;
}
nav.transparent.fixed .menu > li > a,
nav.transparent.fixed .module.widget-handle i {
  opacity: 0.5;
}
nav.bg-dark .menu > li > a,
nav.bg-dark .module.widget-handle i,
nav.bg-dark .nav-utility {
  color: #fff;
  opacity: 0.7;
}
nav.bg-dark .module.widget-handle {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
nav.bg-dark .nav-utility,
nav.bg-dark .nav-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
nav.transparent.fixed.bg-dark {
  background: #292929;
}
nav.transparent.fixed.bg-dark .menu > li > a,
nav.transparent.fixed.bg-dark .module.widget-handle i {
  color: #fff;
}
nav.nav-centered.outOfSight .logo {
  display: none;
}
/*!---------- 8. TOOLTIPS ----------*/
.tooltip {
  font-weight: 600;
  font-size: 13px;
}
.tooltip-inner {
  border-radius: 0;
  padding: 9px 24px 12px 24px;
}
/*!---------- 9. ALERTS ----------*/
.alert {
  margin-bottom: 24px;
  background: none;
  border-radius: 0;
  font-weight: 600;
  padding: 12px 16px;
}
.alert .close {
  opacity: 1;
  font-size: 24px;
  top: -2px;
  right: -4px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.alert .close span {
  font-weight: 400;
}
.alert-warning {
  border: 1px solid #d88519;
  color: #d88519;
}
.alert-success {
  border: 1px solid #60b963;
  color: #60b963;
}
.alert-danger {
  border: 1px solid #c64444;
  color: #c64444;
}
/*!---------- 10. ICONS ----------*/
.icon {
  font-size: 64px;
  display: inline-block;
  color: #292929;
}
.image-bg.bg-light .icon {
  color: #292929;
}
.image-bg .icon,
.bg-dark .icon {
  color: #fff;
}
.icon-lg {
  font-size: 80px;
}
.icon-sm {
  font-size: 32px;
}
.icon-xs {
  font-size: 18px;
}
@media all and (max-width: 767px) {
  .icon {
    font-size: 48px;
  }
  .icon-lg {
    font-size: 64px;
  }
  .icon-sm {
    font-size: 18px;
  }
  .icon-xs {
    font-size: 16px;
  }
}
.embelish-icons {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: scale(10);
  -webkit-transform: scale(10);
  left: 50%;
  opacity: 0.05;
  z-index: -1;
}
/*!---------- 11. FORMS ----------*/
input,
button,
select,
textarea {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
input[type="text"],
button,
textarea,
select,
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bg-secondary input[type="text"],
.bg-secondary textarea,
.bg-secondary select {
  background: #fff;
}
.input-lh {
  line-height: 50px;
}
.attempted-submit .field-error {
  outline: 1px red !important;
}
.input-with-label span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: #292929;
  letter-spacing: 1px;
  font-weight: 700;
  display: block;
  cursor: default;
}
input[type="text"],
input[type="password"] {
  background: #f5f5f5;
  border: none;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  border-radius: 0;
}
.image-bg input[type="text"],
.image-bg textarea {
  color: #555;
}
.image-bg.bg-light input.transparent,
.image-bg.bg-light button.transparent {
  border-color: #292929;
}
input.transparent,
button.transparent {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff !important	;
}
input[type="text"]:focus,
input[type="password"]:focus {
  outline: 1px solid #ccc;
}
textarea {
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 24px;
  border-radius: 0;
  padding: 16px 20px;
}
textarea:focus {
  outline: 1px solid #ccc;
}
::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}
:-moz-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}
::-moz-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}
:-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}
input.transparent::-webkit-input-placeholder,
button.transparent::-webkit-input-placeholder {
  color: #fff;
}
input.transparent::-moz-input-placeholder,
button.transparent::-moz-input-placeholder {
  color: #fff;
}
input.transparent:-moz-input-placeholder,
button.transparent:-moz-input-placeholder {
  color: #fff;
}
input.transparent:-ms-input-placeholder,
button.transparent:-ms-input-placeholder {
  color: #fff;
}
input[type="submit"],
button[type="submit"] {
  height: 50px;
  line-height: 48px;
  border: 2px solid #e5692c;
  background: #e5692c;
  color: #fff;
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 0 !important;
}
.bg-primary input[type="submit"],
.bg-primary button[type="submit"] {
  background: #fff;
  color: #e5692c;
}
input[type="submit"]:focus,
button[type="submit"]:focus {
  outline: none;
}
input[type="submit"].hollow,
button[type="submit"].hollow {
  background: none;
  border: 2px solid #e5692c;
  color: #e5692c;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
input[type="submit"].hollow:hover,
button[type="submit"].hollow:hover {
  background: #e5692c;
  color: #fff;
}
.select-option {
  position: relative;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  margin-bottom: 24px;
}
.select-option i {
  position: absolute;
  font-size: 18px;
  right: 20px;
  top: 14px;
  z-index: 2;
  pointer-events: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  cursor: pointer;
}
.select-option:focus i {
  color: #fff;
}
.select-option select {
  margin-bottom: 0;
}
select {
  height: 50px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none;
  padding-left: 20px;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  cursor: pointer;
}
select:focus {
  background: #eee;
}
select:active,
select:focus,
select:hover {
  outline: none;
  border: none;
}
.checkbox-option,
.radio-option {
  display: inline-block;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid #e5692c;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 24px;
}
.checkbox-option .inner,
.radio-option .inner {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #e5692c;
  position: relative;
  top: 2px;
  left: 2px;
  display: inline-block;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}
.checkbox-option.checked .inner {
  transform: translate3d(25px, 0, 0);
  -webkit-transform: translate3d(25px, 0, 0);
  -moz-transform: translate3d(25px, 0, 0);
  background: #e5692c;
}
.checkbox-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.radio-option {
  width: 25px;
  height: 25px;
  text-align: left;
}
.radio-option:nth-of-type(n + 2) {
  margin-left: 24px;
}
.radio-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.radio-option .inner {
  border: none;
  width: 19px;
  height: 19px;
  left: 2px;
  transform: scale(0);
  -webkit-transform: scale(0);
}
.radio-option + span {
  display: inline-block;
  line-height: 25px;
}
.radio-option.checked .inner {
  transform: scale(1);
  -webkit-transform: scale(1);
}
.radio-option.checked .inner {
  background: #e5692c;
}
@media all and (min-width: 991px) {
  input.col-md-6,
  button.col-md-6 {
    width: 49%;
    float: left;
  }
  input.col-md-6:first-of-type,
  button.col-md-6:first-of-type {
    margin-right: 1%;
  }
  input.col-md-6:last-of-type,
  button.col-md-6:last-of-type {
    margin-left: 1%;
  }
}
form.thirds input,
form.thirds button {
  width: 32%;
  float: left;
  margin-left: 1%;
  margin-bottom: 16px;
}
form.halves input,
form.halves button {
  width: 46%;
  float: left;
  margin-left: 2.5%;
  margin-bottom: 16px;
}
form.halves p,
form.thirds p,
form.halves span,
form.thirds span {
  clear: both;
}
.form-error,
.form-success {
  background: #58ce38;
  padding: 12px;
  width: 80%;
  color: #fff;
  clear: both;
  display: block;
  max-width: 700px;
  position: relative;
  top: 16px;
  margin: 0 auto;
  word-break: break-word;
}
.form-error {
  background: #ce3838;
}
.form-error a,
.form-success a {
  display: block;
  color: #fff;
}
.attempted-submit .field-error {
  outline: 1px solid #ce3838 !important;
}
form iframe.mail-list-form {
  display: none;
}
.form-loading {
  border: 3px solid #ffffff;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  margin: 0px auto;
  top: 50%;
  width: 30px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: pulsate 1s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  z-index: 99999;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
button[type="submit"] .form-loading {
  height: 25px;
  width: 25px;
  display: inline-block;
  top: 9px;
  position: relative;
  left: 0;
}
.btn-white .form-loading {
  border: 3px solid #c8c8c8;
}
@media all and (max-width: 767px) {
  form.thirds input,
  form.halves input,
  form.thirds button,
  form.halves button {
    width: 100%;
    margin-bottom: 8px;
    float: left;
    margin-left: 0;
  }
}
/*!---------- 12. ACCORDIONS ----------*/
.accordion .title {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion .content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}
.accordion li.active .content {
  max-height: 400px;
}
.accordion p:last-child {
  margin-bottom: 0;
}
.accordion-1 li {
  margin-bottom: 10px;
}
.accordion-1 li:last-child {
  margin-bottom: 0;
}
.accordion-1 .title {
  background: #f5f5f5;
  padding: 13px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.bg-secondary .accordion-1 .title {
  background: #ebebeb;
}
.bg-secondary .accordion-1 .title:hover {
  background: #e4e4e4;
}
.accordion-1 .title span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
.accordion-1 .active .title {
  background: #e5692c;
  color: #fff;
}
.accordion-1 .content {
  border: 1px solid #eee;
  border-top: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
}
.accordion-1 .content p {
  padding: 13px;
}
.accordion-1 .active .content {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
}
.accordion-1 .title:hover {
  background: #eee;
}
.accordion-1 .active .title:hover {
  background: #e5692c;
}
.accordion-2 .title {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
  border-bottom: 1px solid #ccc;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.7;
}
.accordion-2 .title:before {
  content: "\e622";
  font-family: "themify";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.accordion-2 .active .title {
  opacity: 1;
}
.accordion-2 .active .title:before {
  content: "\e61a";
}
.accordion-2 .content p {
  padding: 16px 0;
}
.accordion-2 .title:hover {
  opacity: 1;
}
/*!---------- 13. TABBED CONTENT ----------*/
.tabs {
  overflow: hidden;
}
.tabs li {
  display: inline-block;
  cursor: pointer;
}
.tabs.thirds {
  width: 100%;
}
.tabs.thirds li {
  width: 33.3333333%;
}
.tabs.thirds .tab-title {
  width: 100%;
}
.button-tabs .tabs {
  display: inline-block;
  overflow: hidden;
}
.button-tabs .tabs > li {
  margin-bottom: 8px;
  float: left;
}
.button-tabs .tabs > li:last-child {
  margin-right: 0;
}
.button-tabs .tab-title {
  padding: 13px;
  display: inline-block;
  text-align: center;
  min-width: 150px;
  background: #f5f5f5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.bg-secondary .button-tabs .tab-title {
  background: #eee;
}
.button-tabs .tab-title span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
.button-tabs .active .tab-title {
  background: #e5692c;
  color: #fff;
}
.button-tabs .tab-title:hover {
  background: #eee;
}
.button-tabs .active .tab-title:hover {
  background: #e5692c;
}
.button-tabs.vertical {
  overflow: hidden;
}
.button-tabs.vertical > .tabs {
  max-width: 30%;
  float: left;
}
.button-tabs.vertical > .content {
  padding-left: 3%;
  max-width: 70%;
  float: right;
}
.button-tabs.vertical > .tabs li,
.button-tabs.vertical .tab-title {
  width: 100%;
}
.button-tabs.vertical > .tabs li {
  margin-bottom: 10px;
}
.bg-dark .button-tabs .tab-title span {
  color: #222;
}
@media all and (max-width: 767px) {
  .button-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }
  .button-tabs.vertical .tabs,
  .button-tabs.vertical .content {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
  }
}
.tabbed-content .content > li {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  display: none;
}
.tabbed-content .content .active {
  opacity: 1;
  visibility: visible;
  display: block;
}
.text-tabs .tabs {
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  overflow: visible;
}
.text-tabs.no-border .tabs {
  border-bottom: none;
}
.text-tabs .tab-title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  padding: 0 24px;
  opacity: 0.5;
  position: relative;
}
.text-tabs .tab-title span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
.text-tabs .tab-title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e5692c;
  bottom: 10px;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  bottom: -17px;
  left: 0;
}
.text-tabs .active .tab-title {
  opacity: 1;
  color: #e5692c;
}
.bg-primary .text-tabs .tab-title:after {
  background: #f6cab4;
}
.text-tabs .active .tab-title:after {
  opacity: 1;
}
.text-tabs .tab-title:hover {
  opacity: 1;
}
@media all and (max-width: 767px) {
  .text-tabs .tabs {
    padding-bottom: 0;
  }
  .text-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }
  .text-tabs .tab-title:after {
    display: none;
  }
  .text-tabs:not(.text-center) .tabs li:first-child .tab-title {
    padding-left: 24px;
  }
}
.icon-tabs .tabs li {
  width: 24.5%;
  margin-bottom: 24px;
}
.icon-tabs .tab-title {
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.5;
}
.icon-tabs .tab-title:hover {
  opacity: 1;
}
.icon-tabs .active .tab-title {
  color: #e5692c;
  opacity: 1;
}
.icon-tabs .active .tab-title i {
  color: #e5692c;
}
.image-bg .icon-tabs .active .tab-title i {
  color: #fff;
}
.icon-tabs .tab-title i {
  display: block;
  margin-bottom: 16px;
}
.icon-tabs .tab-title span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
@media all and (max-width: 767px) {
  .icon-tabs .tabs li {
    margin-bottom: 16px;
    margin-right: 0;
    width: 49%;
  }
  .icon-tabs .tab-title i {
    font-size: 32px;
  }
}
/*!---------- 14. PROGRESS BARS ----------*/
.progress-bars {
  margin-bottom: 24px;
}
.progress {
  height: auto;
  box-shadow: none;
}
.progress-bar {
  background: #e5692c;
  box-shadow: none !important;
}
.bg-primary .progress-bar {
  background: #e98350;
}
.progress-1 {
  height: 32px;
  margin-bottom: 8px;
  border-radius: 0;
  border: none;
}
.progress-1:last-child {
  margin-bottom: 0;
}
.progress-1 .progress-bar {
  text-align: right;
  padding-right: 12px;
  line-height: 32px;
  border-radius: 0;
}
.progress-1 .progress-bar span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 11px;
}
.progress-2 {
  height: auto;
  background: none;
  text-align: center;
  margin-bottom: 8px;
}
.progress-2 span {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 8px;
}
.progress-2 .bar-holder {
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e5692c;
}
.bg-primary .progress-2 .bar-holder {
  border-color: #ec9062;
}
/*!---------- 15. STATS ----------*/
.stat-2 h1 {
  font-size: 80px;
  line-height: 88px;
}
.stat-3 h1 {
  margin-bottom: 8px;
  font-size: 72px;
  line-height: 80px;
}
.stat-3 h6 {
  margin-bottom: 0;
}
/*!---------- 16. BREADCRUMBS ----------*/
.breadcrumb {
  margin-bottom: 24px;
}
.breadcrumb-2 {
  background: none;
  padding: 0;
}
.breadcrumb-2 li a,
.breadcrumb-2 li {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 11px;
  font-weight: 600;
}
.breadcrumb-2 > li + li:before {
  padding: 0 16px;
  color: #bbb;
}
.image-bg .breadcrumb li a {
  color: #fff;
  opacity: 0.8;
}
.image-bg .breadcrumb li a:hover {
  opacity: 1;
}
.image-bg .breadcrumb li.active {
  color: #fff;
}
.bg-dark .breadcrumb a {
  color: #fff;
}
@media all and (max-width: 990px) {
  .page-title-4 .breadcrumb,
  .page-title-2 .breadcrumb {
    float: left;
    margin-top: 32px;
  }
}
@media all and (max-width: 767px) {
  .breadcrumb-2 > li + li:before {
    padding: 0 4px;
  }
  .page-title-4 .breadcrumb,
  .page-title-2 .breadcrumb {
    float: left;
    margin-top: 8 px;
  }
}
/*!---------- 17. PAGINATION ----------*/
.pagination {
  margin: 0;
}
.pagination li {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.pagination li a {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  padding-top: 6px;
  margin: 0 4px;
  color: #292929;
  font-weight: 400;
}
.pagination li a:hover,
.pagination li.active a,
.pagination li.active:hover a {
  background: #e5692c;
  color: #fff;
  border-color: #e5692c;
}
/*!---------- 18. ICON FEATURES ----------*/
.feature.boxed {
  padding: 32px;
  background: #f8f8f8;
}
.feature.boxed.bg-dark {
  background: #292929;
}
.bg-dark .feature.boxed {
  background: #363636;
}
.bg-secondary .feature.boxed {
  background: #fff;
}
.feature.bordered {
  padding: 32px;
  border: 1px solid #ccc;
}
.feature {
  margin-bottom: 24px;
}
.feature p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .feature.boxed,
  .feature.bordered {
    padding: 24px;
  }
}
@media all and (max-width: 990px) {
  .feature .icon-lg {
    font-size: 40px;
  }
}
.feature-1 i {
  display: inline-block;
  margin-bottom: 16px;
  color: #e5692c;
}
.feature-2 i {
  display: block;
  width: 80px;
  height: 80px;
  border: 1px solid #e5692c;
  text-align: center;
  line-height: 80px;
  color: #e5692c;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 24px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.feature-2 i:hover,
.feature-2.filled i {
  background: #e5692c;
  color: #fff;
}
.feature-3 {
  overflow: hidden;
}
@media all and (min-width: 768px) {
  .feature-3 {
    min-height: 160px;
  }
}
.feature-3 .left,
.feature-3 .right {
  display: inline-block;
  float: left;
}
.feature-3 .left {
  width: 15%;
}
.feature-3 .right {
  width: 85%;
}
.feature-3 i {
  color: #e5692c;
}
.bg-primary .feature-3 i {
  color: #fff;
}
.feature-4 .left {
  width: 25%;
}
.feature-4 .right {
  width: 75%;
}
.feature-color h1,
.feature-color h2,
.feature-color h3,
.feature-color h4,
.feature-color h5,
.feature-color h6 {
  color: #ffffff;
}
.feature-color p,
.feature-color span,
.feature-color li {
  color: #ffffff;
}
.feature-color i {
  color: #fff;
  opacity: 0.7;
}
.feature-color:nth-of-type(1) {
  background: #e5692c;
}
.feature-color:nth-of-type(2) {
  background: #dc5b1b;
}
.feature-color:nth-of-type(3) {
  background: #c65118;
}
.feature-5 {
  height: 33.333333vw;
  padding: 0 48px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
@media all and (max-width: 1280px) {
  .feature-5 {
    height: auto;
    padding: 80px 48px;
  }
  .feature-5 .v-align-transform {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-width: 767px) {
  .feature-3 .left,
  .feature-4 .left {
    margin-bottom: 8px;
  }
  .feature-3 .left,
  .feature-4 .left,
  .feature-3 .right,
  .feature-4 .right {
    width: 100%;
  }
  .feature-5 {
    padding: 40px 24px;
  }
}
.image-bg .feature i {
  color: #fff;
}
.image-bg .feature h1,
.image-bg .feature h2,
.image-bg .feature h3,
.image-bg .feature h4,
.image-bg .feature h5,
.image-bg .feature h6 {
  color: #ffffff;
}
.image-bg .feature p,
.image-bg .feature span,
.image-bg .feature li {
  color: #ffffff;
}
.image-bg .feature.bordered {
  border-color: rgba(255, 255, 255, 0.4);
}
.image-bg .feature.boxed {
  background: rgba(34, 34, 34, 0.4);
}
.bg-light .feature.bordered {
  border-color: rgba(34, 34, 34, 0.2);
}
/*!---------- 19. PRICING TABLES ----------*/
.pricing-table {
  padding: 48px 32px;
}
.pricing-table .price {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 80px;
  line-height: 80px;
  font-weight: 300;
  color: #292929;
  display: inline-block;
  margin-bottom: 16px;
}
.bg-dark .pricing-table .price {
  color: #fff;
}
.pricing-table p:last-child {
  margin-bottom: 0;
}
.pricing-table.boxed,
.pricing-table.emphasis {
  background: #f8f8f8;
}
.pricing-table.emphasis {
  background: #e5692c;
  color: #fff;
}
.pricing-table.emphasis a:not(.btn) {
  color: #fff;
}
.pricing-table.emphasis .price,
.pricing-table.emphasis h5 {
  color: #fff;
}
.pt-2 ul {
  line-height: 36px;
}
/*!---------- 20. PAGE TITLES ----------*/
.page-title {
  padding: 0;
}
.page-title .container {
  position: relative;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  top: 50%;
  z-index: 2;
}
.page-title i {
  position: relative;
  top: 1px;
}
@media all and (max-width: 767px) {
  .page-title i {
    display: none;
  }
}
.page-title-1,
.page-title-2 {
  height: 400px;
}
.page-title-1 .breadcrumb,
.page-title-3 .breadcrumb {
  position: absolute;
  z-index: 3;
  width: 100%;
  text-align: center;
  bottom: 0;
}
.page-title-2 .breadcrumb {
  position: relative;
  margin-bottom: 0;
}
.nav-is-overlay .page-title-1.image-bg:first-child,
.nav-is-overlay.page-title-1.bg-dark:first-child,
.nav-is-overlay .page-title-2.image-bg:first-child,
.nav-is-overlay.page-title-2.bg-dark:first-child {
  height: 455px;
  padding-top: 55px;
}
@media all and (max-width: 767px) {
  .page-title-1,
  .page-title-2 {
    height: 320px;
  }
}
.page-title-3,
.page-title-4 {
  height: 240px;
}
.page-title-4 .breadcrumb {
  position: relative;
  top: 8px;
  margin-bottom: 0;
}
.nav-is-overlay .page-title-3.image-bg:first-child,
.nav-is-overlay.page-title-3.bg-dark:first-child,
.nav-is-overlay .page-title-4.image-bg:first-child,
.nav-is-overlay.page-title-4.bg-dark:first-child {
  height: 295px;
  padding-top: 55px;
}
@media all and (max-width: 767px) {
  .page-title .text-right {
    text-align: left !important;
  }
}
.page-title.fullscreen {
  height: 100vh !important;
  padding-top: 0;
}
/*!---------- 21. WIDGETS ----------*/
.widget {
  margin-bottom: 48px;
}
.link-list {
  line-height: 32px;
}
.link-list a:after {
  content: "\e628";
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 11px;
  display: inline-block;
  margin-left: 8px;
}
.widget ul:last-child,
.widget p:last-child {
  margin-bottom: 0;
}
.widget .title {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 12px;
}
.widget hr {
  margin-bottom: 12px;
}
.recent-posts {
  line-height: 24px;
}
.recent-posts li {
  margin-bottom: 8px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.recent-posts .date {
  display: block;
  letter-spacing: 0;
  opacity: 0.8;
}
.recent-posts li:hover .date {
  opacity: 1;
}
.tags {
  overflow: hidden;
}
.tags li {
  float: left;
  margin: 0 4px 4px 0;
}
.tags .btn-sm {
  padding: 0 8px;
  margin: 0;
}
.widget .gallery {
  overflow: hidden;
}
.widget .gallery li {
  width: 33.33333%;
  float: left;
  padding: 0 4px 4px 0;
}
.twitter-feed .user,
.twitter-feed .interact {
  display: none;
}
.widget .twitter-feed .timePosted {
  display: none;
}
.widget .tweet {
  margin-bottom: 0;
}
.widget .twitter-feed .slides li {
  margin-bottom: 24px;
}
.widget .twitter-feed .slides li:last-child {
  margin-bottom: 0;
}
.widget .twitter-feed .slides li:nth-of-type(n + 3) {
  display: none;
}
.widget .twitter-feed .slides li:before {
  position: relative;
  top: 6px;
  float: left;
  display: inline-block;
  margin-right: 8px;
  color: #292929;
  content: "\e74b";
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 13px;
}
.widget .twitter-feed p {
  padding-left: 22px;
  position: relative;
}
.bg-dark .widget .twitter-feed .slides li:before {
  color: #fff;
}
.widget .instafeed li {
  width: 33.3333%;
  display: inline-block;
  padding: 0 4px 4px 0;
}
.widget .instafeed li:nth-of-type(n + 7) {
  display: none;
}
.widget .cart-overview img {
  width: 25%;
  padding-right: 2%;
}
.widget .cart-overview .description {
  display: inline-block;
  width: auto;
  line-height: 24px;
}
.widget .cart-overview span {
  display: block;
}
.widget .cart-overview li {
  margin-bottom: 24px;
  overflow: hidden;
}
.widget .cart-overview li:last-child {
  margin-bottom: 0;
}
.widget .cart-controls {
  max-height: 30px;
  line-height: 30px;
}
.cart-overview a {
  color: #292929;
}
.cart-overview a:hover {
  color: #e5692c;
}
.widget .flex-direction-nav li a {
  width: 16px;
  height: 16px;
  margin: -8px 0 0;
}
.widget .flex-direction-nav li a:before {
  font-size: 16px;
}
.widget .flex-direction-nav li a.flex-next {
  right: 8px;
}
.widget .flex-direction-nav li a.flex-prev {
  left: 8px;
}
.widget .flex-control-nav {
  display: none;
}
.widget .image-slider {
  margin: 0;
}
/*!---------- 22. IMAGE TILES ----------*/
img {
  max-width: 100%;
}
.image-tile {
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}
.image-tile img {
  width: 100%;
  display: inline-block;
}
.image-tile .label {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
}
.inner-title:before {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.9) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(34, 34, 34, 0.9))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.9) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.9) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.9) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.9) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#292929', GradientType=0);
  pointer-events: none;
}
.inner-title.title-center:before {
  background: #292929;
  opacity: 0.4;
}
.inner-title:hover:before {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.inner-title .title {
  position: absolute;
  width: 100%;
  bottom: 24px;
  z-index: 5;
}
.inner-title .title h1,
.inner-title .title h2,
.inner-title .title h3,
.inner-title .title h4,
.inner-title .title h5,
.inner-title .title h6 {
  color: #ffffff;
}
.inner-title .title p,
.inner-title .title span,
.inner-title .title li {
  color: #ffffff;
}
.inner-title.title-center .title {
  bottom: auto;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
}
.inner-title .title h5 {
  font-weight: 600;
}
.inner-title {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
}
.inner-title:hover {
  opacity: 0.95;
}
.inner-title.hover-reveal:before {
  opacity: 0;
}
.inner-title.hover-reveal .title {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.inner-title.hover-reveal:hover .title {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
}
.inner-title.hover-reveal:hover:before {
  opacity: 1;
}
.outer-title img {
  display: inline-block;
  margin-bottom: 12px;
}
.hover-tile {
  position: relative;
  overflow: hidden;
  background: #292929;
}
.hover-tile img {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.hover-tile:hover img {
  opacity: 0.5;
}
.hover-tile .hover-state {
  position: relative;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  top: 50%;
  z-index: 2;
  position: absolute;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
  width: 100%;
}
.hover-state {
  z-index: 99;
  padding: 0 40px;
  cursor: default;
}
.hover-state h1,
.hover-state h2,
.hover-state h3,
.hover-state h4,
.hover-state h5,
.hover-state h6 {
  color: #ffffff;
}
.hover-state p,
.hover-state span,
.hover-state li {
  color: #ffffff;
}
.hover-state * {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transform: translate3d(0, 30px, 0) scale(1.05);
  -webkit-transform: translate3d(0, 30px, 0) scale(1.05);
}
.hover-tile:hover .hover-state {
  opacity: 1;
}
.hover-tile:hover .hover-state * {
  transform: translate3d(0, 0px, 0) scale(1);
  -webkit-transform: translate3d(0, 0px, 0) scale(1);
}
.hover-state *:last-child {
  margin-bottom: 0;
}
.border-thick {
  border: 16px solid #fff;
  border-top: none;
}
.border-thick.col-sm-6:first-child {
  border-right: 8px solid #fff;
}
.border-thick.col-sm-6:last-child {
  border-left: 8px solid #fff;
}
.horizontal-tile {
  overflow: hidden;
}
.horizontal-tile:not(:last-of-type) {
  margin-bottom: 30px;
}
.tile-left,
.tile-right {
  width: 50%;
  float: left;
  display: inline-block;
  overflow: hidden;
  min-height: 300px;
  position: relative;
}
.tile-right .description,
.tile-left .description {
  padding: 48px;
}
.tile-right:hover .background-image-holder,
.tile-left:hover .background-image-holder {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
@media all and (max-width: 767px) {
  .tile-left,
  .tile-right {
    width: 100%;
    float: none;
  }
}
/*!---------- 23. SLIDERS ----------*/
.image-slider {
  position: relative;
}
.image-slider .slides {
  position: relative;
  overflow: hidden;
}
.image-slider .slides li {
  position: relative;
}
.image-slider .slides li > img {
  width: 100%;
}
.row .image-slider {
  margin-bottom: 24px;
}
.image-slider.height-70 .slides li {
  height: 70vh;
  min-height: 600px;
}
@media all and (max-width: 767px) {
  .image-slider.height-70 .slides li {
    height: auto;
    min-height: none;
    padding: 80px 0;
  }
  .image-slider.height-70 .v-align-transform {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.flex-direction-nav a.flex-next {
  right: 16px;
}
.flex-direction-nav a.flex-next:before,
.flex-direction-nav a.flex-prev:before {
  content: "\e649";
  font-family: "themify";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  color: #fff;
}
.flex-direction-nav a.flex-prev {
  left: 16px;
}
.flex-direction-nav a.flex-prev:before {
  content: "\e64a";
}
.flex-direction-nav a {
  opacity: 1;
  text-shadow: none;
  color: rgba(0, 0, 0, 0);
  width: 24px;
  height: 24px;
  margin: -12px 0 0;
}
.flex-control-nav li {
  overflow: hidden;
}
.flex-control-nav li a {
  background: none;
  border: 2px solid #292929;
  width: 10px;
  height: 10px;
}
@media all and (max-width: 768px) {
  .flex-direction-nav {
    display: none;
  }
}
.controls-inside .flex-control-nav {
  bottom: 24px;
  z-index: 10;
}
.controls-inside .flex-control-nav li a {
  border-color: #fff;
}
.controls-inside .flex-control-nav li a.flex-active {
  background: #fff;
}
.image-bg .flex-control-nav li a {
  border-color: #fff;
}
.image-bg .flex-control-nav li a.flex-active {
  background: #fff;
}
.slider-thumb-controls .flex-control-thumbs {
  margin: 0;
}
.slider-thumb-controls .flex-direction-nav {
  display: none;
}
@media all and (max-width: 767px) {
  .slider-thumb-controls .flex-control-thumbs {
    display: none;
  }
  .slider-thumb-controls .flex-direction-nav {
    display: block;
  }
}
.logo-carousel li {
  text-align: center;
}
.logo-carousel li {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.5;
}
.logo-carousel li:hover {
  opacity: 1;
}
.logo-carousel img {
  max-height: 60px;
}
@media all and (max-width: 767px) {
  .logo-carousel .slides li {
    width: 100%;
  }
}
.text-slider .flex-direction-nav li a:before {
  color: #222;
}
.image-bg .text-slider .flex-direction-nav li a:before {
  color: #fff;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.5;
}
.image-bg .text-slider .flex-direction-nav li a:hover:before {
  opacity: 1;
}
.text-slider .flex-direction-nav a.flex-prev {
  left: -60px;
}
.text-slider .flex-direction-nav a.flex-next {
  right: -60px;
}
.kenburns .slides li:nth-of-type(odd) .background-image-holder {
  animation: kenBurnsEast 25s linear infinite alternate;
  -webkit-animation: kenBurnsEast 25s linear infinite alternate;
}
.kenburns .slides li:nth-of-type(even) .background-image-holder {
  animation: kenBurnsWest 25s linear infinite alternate;
  -webkit-animation: kenBurnsWest 25s linear infinite alternate;
}
@keyframes kenBurnsEast {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}
@-webkit-keyframes kenBurnsEast {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}
@keyframes kenBurnsWest {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}
@-webkit-keyframes kenBurnsWest {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}
@media all and (max-width: 767px) {
  .kenburns .slides li .background-image-holder {
    animation: none !important;
    -webkit-animation: none !important;
  }
}
/*!---------- 24. IMAGE GALLERIES ----------*/
.instafeed.grid-gallery {
  overflow: hidden;
}
.instafeed.grid-gallery li {
  width: 25%;
  display: inline-block;
  float: left;
  padding: 8px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
}
.instafeed.grid-gallery li:hover {
  opacity: 0.9;
}
.instafeed.grid-gallery.gapless li {
  padding: 0;
  width: 16.66667%;
}
.instafeed.grid-gallery.gapless li img {
  width: 100%;
}
.instafeed li img {
  height: auto;
}
@media all and (max-width: 990px) {
  .instafeed.grid-gallery li {
    width: 33.333333%;
  }
}
.lightbox-grid,
.lightbox-grid ul,
.lightbox-grid li {
  position: relative;
  overflow: hidden;
}
.lightbox-grid li {
  width: 25%;
  float: left;
  border: 8px solid rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
}
.lightbox-grid li:hover {
  opacity: 0.9;
}
.square-thumbs li {
  height: 285px;
}
.third-thumbs li {
  width: 33.33333vw;
  height: 33vw;
  border: none;
}
@media all and (max-width: 1190px) {
  .square-thumbs li {
    height: 235px;
  }
}
@media all and (max-width: 990px) {
  .lightbox-grid li {
    width: 33.33333%;
  }
  .square-thumbs li {
    height: 240px;
  }
}
@media all and (max-width: 767px) {
  .lightbox-grid li {
    width: 50%;
  }
  .third-thumbs li {
    height: 50vw;
  }
  .square-thumbs li {
    height: 185px;
  }
}
.lb-outerContainer {
  background: none;
  border-radius: 0;
}
.lightbox .lb-image {
  border-radius: 0;
}
.lb-data .lb-close,
.lb-nav a.lb-next,
.lb-nav a.lb-prev {
  background: none;
}
.lb-data .lb-close:before,
.lb-nav a.lb-next:before,
.lb-nav a.lb-prev:before {
  content: "\e646";
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  color: #fff;
}
.lb-nav a.lb-next:before,
.lb-nav a.lb-prev:before {
  position: absolute;
  top: 50%;
  font-size: 32px;
  margin-top: -16px;
}
.lb-nav a.lb-next:before {
  content: "\e649";
  right: 16px;
}
.lb-nav a.lb-prev:before {
  content: "\e64a";
  left: 16px;
}
.flickr-feed li {
  width: 25%;
  padding: 8px;
}
.flickr-feed li.masonry-item {
  margin-bottom: 0;
}
.flickr-feed li img {
  min-width: 100%;
}
@media all and (max-width: 990px) {
  .flickr-feed li {
    width: 33.33333%;
  }
}
@media all and (max-width: 767px) {
  .flickr-feed li {
    padding: 4px;
  }
}
/*!---------- 25. INTROS ----------*/
.cover {
  margin: 0;
  padding: 0;
}
.cover h1,
.cover h2,
.cover h3,
.cover h4,
.cover h5,
.cover h6 {
  color: #ffffff;
}
.cover p,
.cover span,
.cover li {
  color: #ffffff;
}
.image-bg.bg-light h1,
.image-bg.bg-light h2,
.image-bg.bg-light h3,
.image-bg.bg-light h4,
.image-bg.bg-light h5,
.image-bg.bg-light h6 {
  color: #222222;
}
.image-bg.bg-light p,
.image-bg.bg-light span,
.image-bg.bg-light li {
  color: #777777;
}
.cover:first-child .fullscreen {
  max-height: 100%;
}
.cover.fullscreen .slides > li {
  height: 100vh;
}
.cover .slides li .container {
  z-index: 4;
  position: relative;
}
.cover .flex-control-nav {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.9;
}
.cover .flex-control-nav:hover {
  opacity: 1;
}
.cover .align-bottom {
  padding: 0 32px;
  bottom: 16px;
}
.cover .align-top {
  padding: 0 32px;
}
/* Removed this as per Scott Request so image height takes the whole mobile screen */
/* @media all and (max-width: 767px) {
  .cover .align-bottom {
    padding: 0 15px;
  }
  .cover .align-bottom.relative-xs {
    position: relative;
    bottom: 0;
  }
  .cover.fullscreen {
    height: auto;
    padding: 80px 0;
  }
  .cover.fullscreen.image-slider {
    padding: 0;
  }
  .cover.fullscreen .container {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
  .cover.fullscreen .slides li {
    height: auto;
    padding: 80px 0;
  }
} */
/*!---------- 26. SHOP ----------*/
.product-single .image-slider .label {
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  top: 16px;
  right: 16px;
}
.product-single .price {
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  display: inline-block;
  margin-right: 32px;
}
.product-single .old-price {
  text-decoration: line-through;
  opacity: 0.7;
}
.product-single .price:not(.old-price) {
  color: #e5692c;
}
.add-to-cart input[type="text"] {
  width: 100px;
  text-align: center;
  padding: 0;
  margin: 0;
}
.add-to-cart input[type="submit"] {
  width: 160px;
  margin: 0;
}
th,
td {
  line-height: 24px !important;
}
.ratings {
  margin-bottom: 24px;
}
.ratings li {
  display: inline-block;
  background: #f8f8f8;
  padding: 24px;
  margin-bottom: 16px;
}
.star-rating li {
  padding: 0;
  color: #000;
}
.star-rating {
  margin: 0;
  display: inline-block;
  margin-right: 24px;
}
.ratings .user span {
  display: inline-block;
  margin-right: 8px;
}
.ratings .user .date {
  opacity: 0.7;
}
.ratings .user {
  margin-bottom: 8px;
}
.ratings-form input[type="text"] {
  width: 50%;
  float: left;
}
.ratings-form input[type="text"]:first-child {
  width: 48%;
  margin-right: 2%;
}
.ratings-form input[type="submit"] {
  width: 200px;
}
.product-thumb {
  max-width: 180px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.col-sm-6 .product-thumb {
  max-width: 320px;
}
.product-thumb:hover {
  transform: scale3d(0.95, 0.95, 0.95);
  -webkit-transform: scale3d(0.95, 0.95, 0.95);
}
.cart .product-thumb {
  max-width: 10000px;
  max-height: 100px;
}
.cart .product-thumb:hover {
  transform: none;
}
.cart td,
.cart span,
.cart th {
  vertical-align: middle !important;
}
.cart span {
  display: inline-block;
}
.cart .remove-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  border: 1px solid #c64444;
  color: #c64444;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.cart .remove-item:hover {
  color: #fff;
  background: #c64444;
}
/*!---------- 27. VIDEO ----------*/
video:-webkit-full-screen,
audio:-webkit-full-screen {
  -webkit-transform: translateY(0%);
}
iframe {
  border: none;
}
.player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
@media all and (max-width: 767px) {
  .player {
    display: none;
  }
}
.vid-bg {
  background: #222;
}
.vid-bg .background-image-holder {
  display: none;
  z-index: 0;
}
.vid-bg .masonry-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}
.vid-bg .container.fadeOut {
  opacity: 0;
}
@media all and (max-width: 1024px) {
  .vid-bg .background-image-holder {
    display: block;
  }
  .vid-bg .player {
    display: none;
  }
  .vid-bg .container.fadeOut {
    opacity: 1;
  }
  .vid-bg .masonry-loader {
    display: none;
  }
}
video {
  max-width: 100%;
}
.local-video-container {
  position: relative;
  margin-bottom: 24px;
}
.local-video-container video {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.local-video-container .background-image-holder {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  top: 0;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.26);
}
.background-image-holder.fadeout {
  opacity: 0 !important;
  z-index: 0;
}
@media all and (max-width: 767px) {
  video {
    max-width: 100% !important;
  }
}
.play-button {
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 4;
  border-radius: 50%;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  cursor: pointer;
}
.play-button:before {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -5px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 14px;
  border-color: transparent transparent transparent #ffffff;
}
.play-button:hover {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
}
.play-button.dark {
  border-color: #292929;
}
.play-button.dark:before {
  border-color: transparent transparent transparent #292929;
}
@media all and (max-width: 767px) {
  .play-button {
    width: 65px;
    height: 65px;
    margin-top: -32px;
  }
  .play-button:before {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    margin-left: -8px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #fff;
  }
}
.play-button.large {
  height: 120px;
  width: 120px;
  margin-top: -60px;
  margin-left: -60px;
}
.play-button.large:before {
  margin-top: -18px;
  margin-left: -10px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 31px;
  border-color: transparent transparent transparent #ffffff;
}
.play-button.large.dark:before {
  border-color: transparent transparent transparent #292929;
}
.play-button.inline {
  position: relative;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  display: inline-block;
  z-index: 0;
}
@media all and (max-width: 767px) {
  .play-button.large {
    width: 90px;
    height: 90px;
    margin-top: -45px;
  }
  .play-button.large:before {
    margin-top: -14px;
    margin-left: -8px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 25px;
    border-color: transparent transparent transparent #fff;
  }
}
.modal-video {
  position: fixed;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
  z-index: -1;
}
.modal-video video {
  margin: 0 auto;
  width: 675px;
  display: block;
  top: 50%;
  position: relative;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.modal-video.reveal-modal {
  opacity: 1;
  z-index: 999999;
  visibility: visible;
}
.modal-video iframe {
  position: absolute;
  width: 768px;
  height: 432px;
  left: 50%;
  margin-left: -384px;
  margin-top: -216px;
  top: 50%;
  border: none;
}
.modal-video .ti-close {
  font-size: 24px;
  position: absolute;
  top: 32px;
  right: 32px;
  color: #fff;
  cursor: pointer;
}
@media all and (max-width: 990px) {
  .modal-video iframe {
    width: 640px;
    height: 360px;
    margin-left: -320px;
    margin-top: -160px;
  }
}
@media all and (max-width: 767px) {
  .modal-video video {
    width: 100%;
    padding: 0 15px;
  }
  .modal-video iframe {
    width: 100%;
    margin-left: -50%;
  }
}
.fs-video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.fs-video-wrapper video {
  position: absolute;
  width: 100%;
  left: 0;
}
.embed-video-container {
  width: 100%;
  position: relative;
  display: inline-block;
}
.embed-video-container iframe {
  border: none;
  width: 100%;
  height: 312px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.modal-video .embed-video-container {
  width: 80%;
  max-height: 800px;
}
@media all and (max-width: 767px) {
  .embed-video-container iframe {
    height: 200px;
  }
}
.fs-vid-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.fs-vid-background video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.overlay .fs-vid-background:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #222222;
  opacity: 0.5;
  z-index: 2;
}
@media all and (max-width: 1024px) {
  .fs-vid-background {
    display: none;
  }
}
/*!---------- 28. BLOG ----------*/
.post-title .label {
  margin-right: 16px;
}
.post-title h4 {
  position: relative;
  top: 2px;
  margin-bottom: 16px;
}
.post-meta,
.post-meta .tags {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 12px;
}
.post-meta > li {
  float: left;
  margin-right: 24px;
}
.post-meta i {
  font-size: 16px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.post-snippet iframe {
  width: 100%;
  margin-bottom: 12px;
}
.post-snippet .embed-video-container {
  margin-bottom: 24px;
}
.post-snippet .embed-video-container iframe {
  margin: 0;
}
.post-snippet .inner {
  background: #fff;
  padding: 24px;
}
.comments-list,
.comments-list ul {
  width: 100%;
  overflow: hidden;
}
.comments-list li {
  overflow: hidden;
  margin-bottom: 40px;
}
.comments-list p:last-of-type {
  margin: 0;
}
.comments-list .avatar,
.comments-list .comment {
  display: inline-block;
  float: left;
}
.comments-list .avatar {
  width: 10%;
}
.comments-list .avatar img {
  width: 75px;
}
.comments-list .comment {
  width: 90%;
  padding-left: 5%;
}
.comments-list .author {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
}
.comments-list .comment .btn {
  position: relative;
  margin: 0;
}
.comments-list ul {
  padding-left: 10%;
  padding-top: 40px;
}
.comments-list ul li:last-of-type {
  margin-bottom: 0;
}
.comments input[type="submit"] {
  max-width: 25%;
  float: right;
}
.masonry {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
  -moz-transform: translate3d(0, 100px, 0);
}
.masonry.fadeIn {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.masonry-item {
  max-width: 100%;
}
.container .masonry-item {
  margin-bottom: 30px;
}
.masonry-item blockquote:hover {
  background: #e5692c;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  color: #fff;
}
.masonryFlyIn .masonry-item {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
}
.masonryFlyIn .masonry-item.fadeIn {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.masonry-loader {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 80px;
}
.masonry-loader.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  margin-top: -25px;
}
.masonry-loader.fadeOut {
  opacity: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
.spinner {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 2px 1px 0px #e5692c;
  -moz-animation: spin 0.7s linear infinite;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media all and (max-width: 767px) {
  .post-title .label {
    margin-bottom: 8px;
    display: inline-block;
  }
  .post-title h4 {
    top: 0;
    display: block;
  }
  .comments input[type="submit"] {
    max-width: 100%;
    float: none;
  }
}
/*!---------- 29. IMAGE BLOCKS ----------*/
.image-md {
  max-height: 120px;
}
.image-small {
  max-height: 80px;
}
.image-xs {
  max-height: 50px;
}
.image-xxs {
  max-height: 30px;
}
.fade-half {
  opacity: 0.5;
}
.fade-1-4 {
  opacity: 0.75;
}
.fade-3-4 {
  opacity: 0.25;
}
.fade-on-hover {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.5;
}
.fade-on-hover:hover {
  opacity: 1;
}
.cast-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.cast-shadow-light {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}
.image-block,
.image-block .image-bg {
  padding: 0;
  height: 50vw;
  max-height: 700px;
}
.image-block .container {
  height: 100%;
}
.image-block-right .image-bg {
  position: absolute;
  top: 0;
}
@media all and (max-width: 768px) {
  .image-block {
    height: auto;
    max-height: 1000000px;
  }
  .image-block .image-bg {
    height: 350px;
  }
  .image-block .v-align-transform {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
  .image-block-right .image-bg {
    position: relative;
  }
}
.image-edge .container {
  position: relative;
}
.image-edge .container div[class*="col-"] {
  position: absolute;
}
@media all and (max-width: 990px) {
  .image-edge .container div[class*="col-"] {
    position: relative;
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.image-square {
  height: 50vw;
  max-height: 640px;
  overflow: hidden;
  padding: 0;
}
.image-square .image,
.image-square > .content {
  position: absolute;
}
.image-square .image {
  height: 100%;
}
.image-square > .content {
  padding: 0 10%;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
}
.image-square.left .image {
  left: 0;
}
.image-square.left > .content {
  right: 0;
}
.image-square.right .image {
  right: 0;
}
.image-square.right > .content {
  left: 0;
}
@media all and (max-width: 990px) {
  .image-square {
    height: auto;
    max-height: 100000px;
    padding-bottom: 48px;
  }
  .image-square .image,
  .image-square > .content {
    position: relative;
  }
  .image-square .image {
    height: 100vw;
    margin-bottom: 48px;
    max-height: 500px;
  }
  .image-square > .content {
    top: 0;
    transform: none;
    -webkit-transform: none;
    padding: 0 20%;
  }
}
@media all and (max-width: 767px) {
  .image-square > .content {
    padding: 0 15px;
  }
}
.image-zoom img {
  transition: all 9s ease;
  -webkit-transition: all 9s ease;
  -moz-transition: all 9s ease;
}
.image-zoom:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.image-caption {
  position: relative;
  overflow: hidden;
}
.image-caption .caption {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  color: #fff;
}
.image-caption .caption p {
  position: relative;
  z-index: 4;
  margin: 0;
}
.image-caption .caption:before {
  content: "" attr(data-caption) "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 1;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.7) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(34, 34, 34, 0.7))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.7) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.7) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.7) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 34, 34, 0.7) 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e6222222', GradientType=0);
  /* IE6-9 */
}
.image-caption.hover-caption .caption {
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
  -moz-transform: translate3d(0, 100px, 0);
}
.image-caption.hover-caption:hover .caption {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
/*!---------- 30. PORTOFLIO ----------*/
.filters {
  overflow: hidden;
  display: inline-block;
}
.filters li {
  float: left;
  margin-right: 32px;
  cursor: pointer;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 2px solid #e5692c;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  color: #e5692c;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  margin-right: 8px;
  margin-bottom: 24px;
  height: 30px;
  font-size: 11px;
  line-height: 27px;
  min-width: 0;
  border-color: rgba(255, 255, 255, 0);
  opacity: 0.7;
  border-radius: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.filters li.active {
  border-color: #e5692c;
  opacity: 1;
}
.filters li:last-child {
  margin-right: 0;
}
.filters.floating {
  position: absolute;
  z-index: 10;
  top: 16px;
  text-align: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  background: rgba(255, 255, 255, 0.98);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  border-radius: 200px;
}
.filters.floating li {
  margin-bottom: 0;
}
.bg-dark .filters.floating {
  background: #292929;
}
.bg-dark .filters.floating h1,
.bg-dark .filters.floating h2,
.bg-dark .filters.floating h3,
.bg-dark .filters.floating h4,
.bg-dark .filters.floating h5,
.bg-dark .filters.floating h6 {
  color: #ffffff;
}
.bg-dark .filters.floating p,
.bg-dark .filters.floating span,
.bg-dark .filters.floating li {
  color: #fefefe;
}
.project {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 1;
  padding: 0;
}
.container .project {
  padding: 0 15px;
}
.project.inactive {
  opacity: 0.1 !important;
  pointer-events: none !important;
}
.project .image-tile {
  margin: 0;
}
.project.inactive:hover .title,
.project.inactive .image-tile:hover:before {
  display: none;
}
@media all and (max-width: 868px) {
  .filters.floating {
    width: 90vw;
  }
  .filters.floating li {
    float: none;
  }
}
@media all and (max-width: 767px) {
  .filters.floating {
    border-radius: 0;
    padding: 8px;
  }
  .filters.floating li {
    width: 50%;
    float: left;
    margin: 0;
  }
}
section + .portfolio-pullup {
  padding-top: 0;
  margin-top: -128px;
  position: relative;
  z-index: 20;
}
.portfolio-pullup .project {
  margin-bottom: 0;
}
.portfolio-pullup .inner-title:hover {
  opacity: 1;
}
/*!---------- 31. TESTIMONIALS ----------*/
.testimonials h4 {
  line-height: 40px;
}
.testimonials .quote-author {
  display: inline-block;
  text-align: left;
  overflow: hidden;
  width: 250px;
  margin-top: 24px;
}
.testimonials .quote-author img {
  max-height: 60px;
  float: left;
  display: inline-block;
  margin-right: 16px;
}
.testimonials .quote-author h6 {
  display: inline-block;
  margin-bottom: 0;
  line-height: 18px;
  padding-top: 14px;
}
.testimonials .quote-author span {
  display: block;
  line-height: 18px;
}
@media all and (max-width: 767px) {
  .testimonials h4 {
    line-height: 26px;
  }
}
/*!---------- 32. COUNTDOWNS ----------*/
.countdown {
  font-size: 80px;
  line-height: 88px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 56px;
  line-height: 64px;
  font-weight: 100;
  text-transform: uppercase;
}
.image-bg .countdown {
  color: #fff;
}
@media all and (max-width: 990px) {
  .countdown {
    font-size: 64px;
    line-height: 72px;
  }
}
@media all and (max-width: 767px) {
  .countdown {
    font-size: 48px;
    line-height: 56px;
  }
}
/*!---------- 33. MAPS ----------*/
.map-holder {
  overflow: hidden;
  position: relative;
}
.map-holder iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 180%;
  top: -40%;
  left: 0;
  z-index: 1;
}
.map-holder:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.map-holder.interact:before {
  width: 0;
}
.map-holder.inline {
  height: 400px;
  overflow: hidden;
}
.map-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
/*!---------- 34. TWITTER ----------*/
.twitter-feed.thirds li {
  width: 33.333333%;
  padding: 0 15px;
  float: left;
}
.twitter-feed.thirds ul {
  overflow: hidden;
}
@media all and (max-width: 990px) {
  .twitter-feed.thirds li {
    width: 50%;
  }
}
@media all and (max-width: 767px) {
  .twitter-feed.thirds li {
    width: 100%;
  }
}
.tweets-slider.large p {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 32px;
}
.tweets-slider.large .timePosted {
  font-size: 14px;
}
@media all and (max-width: 990px) {
  .tweets-slider.large p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media all and (max-width: 767px) {
  .tweets-slider.large p {
    font-size: 14px;
  }
}
.sc-background-orange {
  background: #e5692c !important;
}
/*!---------- 35. MODALS ----------*/
.foundry_modal {
  overflow-y: auto;
  max-height: 100%;
  max-width: 50%;
  width: 50%;
  position: fixed;
  padding: 80px 48px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  top: 50%;
  left: 50%;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}
.foundry_modal.no-bg {
  background: none;
  box-shadow: none !important;
}
.modal-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #292929;
  opacity: 0;
  z-index: 9998;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  visibility: hidden;
  top: 0;
  left: 0;
}
.modal-screen.reveal-modal {
  opacity: 0.8;
  visibility: visible;
}
.foundry_modal.reveal-modal {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
.foundry_modal .close-modal {
  z-index: 4;
  position: absolute;
  top: 24px;
  right: 32px;
  cursor: pointer;
  font-size: 18px;
}
.foundry_modal *:not(.background-image-holder):not(.close-modal) {
  z-index: 4;
  position: relative;
}
.foundry_modal.image-bg .close-modal,
.foundry_modal.no-bg .close-modal {
  color: #fff;
}
.foundry_modal iframe {
  width: 100%;
  min-height: 400px;
}
@media all and (min-width: 1500px) {
  .foundry_modal {
    max-width: 45%;
    width: 45%;
  }
}
@media all and (max-width: 1190px) {
  .foundry_modal {
    max-width: 70%;
    width: 70%;
    padding: 64px 32px;
  }
}
@media all and (max-width: 990px) {
  .foundry_modal {
    max-width: 80%;
    width: 80%;
    padding: 64px 32px;
  }
}
@media all and (max-width: 767px) {
  .foundry_modal {
    max-width: 90%;
    width: 90%;
    padding: 48px 15px;
    transform: translateX(0%) translateY(50%);
    -webkit-transform: translateX(0%) translateY(50%);
  }
  .foundry_modal .close-modal {
    right: 18px;
  }
}
.iframe-modal {
  width: 90%;
  height: 90%;
  max-width: 90%;
  padding: 0;
}
.iframe-modal .close-modal {
  z-index: 9999;
}
.iframe-modal iframe {
  width: 100%;
  height: 100%;
}
.modal-strip {
  padding: 18px 0;
  position: fixed;
  bottom: 1%;
  z-index: 200;
  width: 98%;
  margin: 0 1%;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transform: translate3d(0, 100px, 0);
  -webkit-transform: translate3d(0, 100px, 0);
  -moz-transform: translate3d(0, 100px, 0);
  opacity: 0;
}
.modal-strip.reveal-modal {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  opacity: 1;
}
.modal-strip .close-modal {
  position: absolute;
  right: 32px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.modal-strip i.close-modal {
  font-size: 18px;
}
.modal-strip .icon + p {
  padding-left: 32px;
  position: relative;
  top: 3px;
}
.modal-strip p + .btn {
  margin-left: 24px;
}
@media all and (max-width: 1024px) {
  .modal-strip {
    margin: 0;
    width: 100%;
    bottom: 0;
  }
}
@media all and (max-width: 767px) {
  .modal-strip .close-modal {
    position: relative;
    top: 0;
    right: 0;
    left: 15px;
    transform: none !important;
    -webkit-transform: none !important;
    margin-top: 16px;
  }
  .modal-strip .icon {
    display: none;
  }
  .modal-strip .icon + p {
    padding-left: 0;
    top: 0;
  }
}
/*!---------- 36. FOOTERS ----------*/
footer.bg-dark a {
  color: #fff;
}
.footer-1 .logo {
  max-height: 30px;
}
.footer-1 .sub {
  opacity: 0.5;
}
.social-list {
  margin: 0;
}
.image-bg .social-list a {
  color: #fff;
}
.bg-dark .social-list a,
.image-bg .social-list a {
  color: #fff;
  opacity: 0.5;
}
.bg-dark .social-list a:hover {
  opacity: 1;
}
.image-bg .social-list a {
  opacity: 1;
}
.bg-light .social-list a {
  color: #222;
}
.back-to-top {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.back-to-top:hover {
  opacity: 1;
}
.bg-dark .back-to-top {
  border-color: #fff;
}
.bg-dark .back-to-top:active,
.bg-dark .back-to-top:focus {
  color: #fff;
}
.bg-dark .back-to-top:hover {
  background: none;
}
/*!---------- 36. SPACING ----------*/
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb8 {
  margin-bottom: 8px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb56 {
  margin-bottom: 56px;
}
.mb64 {
  margin-bottom: 64px;
}
.mb72 {
  margin-bottom: 72px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb88 {
  margin-bottom: 88px;
}
.mb96 {
  margin-bottom: 96px;
}
.mb104 {
  margin-bottom: 104px;
}
.mb112 {
  margin-bottom: 112px;
}
.mb120 {
  margin-bottom: 120px;
}
.mb160 {
  margin-bottom: 160px;
}
.mt0 {
  margin-top: 0 !important;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mt48 {
  margin-top: 48px;
}
.mt56 {
  margin-top: 56px;
}
.mt64 {
  margin-top: 64px;
}
.mt72 {
  margin-top: 72px;
}
.mt80 {
  margin-top: 80px;
}
.mt88 {
  margin-top: 88px;
}
.mt96 {
  margin-top: 96px;
}
.mt104 {
  margin-top: 104px;
}
.mt112 {
  margin-top: 112px;
}
.mt120 {
  margin-top: 120px;
}
.mt160 {
  margin-top: 160px;
}
.p32 {
  padding: 0 32px;
}
.p24 {
  padding: 24px;
}
.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pt8 {
  padding-top: 8px;
}
.pt16 {
  padding-top: 16px;
}
.pt24 {
  padding-top: 24px;
}
.pt32 {
  padding-top: 32px;
}
.pt40 {
  padding-top: 40px;
}
.pt48 {
  padding-top: 48px;
}
.pt64 {
  padding-top: 64px;
}
.pt72 {
  padding-top: 72px;
}
.pt80 {
  padding-top: 80px;
}
.pt88 {
  padding-top: 88px;
}
.pt96 {
  padding-top: 96px;
}
.pt104 {
  padding-top: 104px;
}
.pt112 {
  padding-top: 112px;
}
.pt120 {
  padding-top: 120px;
}
.pt160 {
  padding-top: 160px;
}
.pt180 {
  padding-top: 180px;
}
.pt240 {
  padding-top: 240px;
}
.pb0 {
  padding-bottom: 0;
}
.pb8 {
  padding-bottom: 8px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb32 {
  padding-bottom: 32px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb48 {
  padding-bottom: 48px;
}
.pb56 {
  padding-bottom: 56px;
}
.pb64 {
  padding-bottom: 64px;
}
.pb72 {
  padding-bottom: 72px;
}
.pb80 {
  padding-bottom: 80px;
}
.pb88 {
  padding-bottom: 88px;
}
.pb96 {
  padding-bottom: 96px;
}
.pb104 {
  padding-bottom: 104px;
}
.pb112 {
  padding-bottom: 112px;
}
.pb120 {
  padding-bottom: 120px;
}
.pb160 {
  padding-bottom: 160px;
}
.pb180 {
  padding-bottom: 180px;
}
.pb240 {
  padding-bottom: 240px;
}
@media all and (max-width: 990px) {
  .mb-s-0 {
    margin-bottom: 0;
  }
  .mb-s-8 {
    margin-bottom: 8px;
  }
  .mb-s-16 {
    margin-bottom: 16px;
  }
  .mb-s-24 {
    margin-bottom: 24px;
  }
  .mb-s-32 {
    margin-bottom: 32px;
  }
  .mb-s-40 {
    margin-bottom: 40px;
  }
  .mb-s-48 {
    margin-bottom: 48px;
  }
  .mb-s-64 {
    margin-bottom: 64px;
  }
  .mb-s-72 {
    margin-bottom: 72px;
  }
  .mb-s-80 {
    margin-bottom: 80px;
  }
  .mb-s-88 {
    margin-bottom: 88px;
  }
  .mb-s-96 {
    margin-bottom: 96px;
  }
  .mb-s-104 {
    margin-bottom: 104px;
  }
  .mb-s-112 {
    margin-bottom: 112px;
  }
  .mb-s-120 {
    margin-bottom: 120px;
  }
}
@media all and (max-width: 990px) {
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-8 {
    margin-bottom: 8px;
  }
  .mb-sm-16 {
    margin-bottom: 16px;
  }
  .mb-sm-24 {
    margin-bottom: 24px;
  }
  .mb-sm-32 {
    margin-bottom: 32px;
  }
  .mb-sm-40 {
    margin-bottom: 40px;
  }
  .mb-sm-48 {
    margin-bottom: 48px;
  }
  .mb-sm-64 {
    margin-bottom: 64px;
  }
  .mb-sm-72 {
    margin-bottom: 72px;
  }
  .mb-sm-80 {
    margin-bottom: 80px;
  }
  .mb-sm-88 {
    margin-bottom: 88px;
  }
  .mb-sm-96 {
    margin-bottom: 96px;
  }
  .mb-sm-104 {
    margin-bottom: 104px;
  }
  .mb-sm-112 {
    margin-bottom: 112px;
  }
  .mb-sm-120 {
    margin-bottom: 120px;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-8 {
    margin-top: 8px;
  }
  .mt-sm-16 {
    margin-top: 16px;
  }
  .mt-sm-24 {
    margin-top: 24px;
  }
  .mt-sm-32 {
    margin-top: 32px;
  }
  .mt-sm-40 {
    margin-top: 40px;
  }
  .mt-sm-48 {
    margin-top: 48px;
  }
  .mt-sm-56 {
    margin-top: 56px;
  }
  .mt-sm-64 {
    margin-top: 64px;
  }
  .mt-sm-72 {
    margin-top: 72px;
  }
  .mt-sm-80 {
    margin-top: 80px;
  }
  .mt-sm-88 {
    margin-top: 88px;
  }
  .mt-sm-96 {
    margin-top: 96px;
  }
  .mt-sm-104 {
    margin-top: 104px;
  }
  .mt-sm-112 {
    margin-top: 112px;
  }
  .mt-sm-120 {
    margin-top: 120px;
  }
  .p0-sm {
    padding: 0;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-8 {
    padding-top: 8px;
  }
  .pt-sm-16 {
    padding-top: 16px;
  }
  .pt-sm-24 {
    padding-top: 24px;
  }
  .pt-sm-32 {
    padding-top: 32px;
  }
  .pt-sm-40 {
    padding-top: 40px;
  }
  .pt-sm-48 {
    padding-top: 48px;
  }
  .pt-sm-64 {
    padding-top: 64px;
  }
  .pt-sm-72 {
    padding-top: 72px;
  }
  .pt-sm-80 {
    padding-top: 80px;
  }
  .pt-sm-88 {
    padding-top: 88px;
  }
  .pt-sm-96 {
    padding-top: 96px;
  }
  .pt-sm-104 {
    padding-top: 104px;
  }
  .pt-sm-112 {
    padding-top: 112px;
  }
  .pt-sm-120 {
    padding-top: 120px;
  }
  .pt-sm-160 {
    padding-top: 160px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-8 {
    padding-bottom: 8px;
  }
  .pb-sm-16 {
    padding-bottom: 16px;
  }
  .pb-sm-24 {
    padding-bottom: 24px;
  }
  .pb-sm-32 {
    padding-bottom: 32px;
  }
  .pb-sm-40 {
    padding-bottom: 40px;
  }
  .pb-sm-48 {
    padding-bottom: 48px;
  }
  .pb-sm-64 {
    padding-bottom: 64px;
  }
  .pb-sm-72 {
    padding-bottom: 72px;
  }
  .pb-sm-80 {
    padding-bottom: 80px;
  }
  .pb-sm-88 {
    padding-bottom: 88px;
  }
  .pb-sm-96 {
    padding-bottom: 96px;
  }
  .pb-sm-104 {
    padding-bottom: 104px;
  }
  .pb-sm-112 {
    padding-bottom: 112px;
  }
  .pb-sm-120 {
    padding-bottom: 120px;
  }
}
@media all and (max-width: 767px) {
  .mb-xs-0 {
    margin-bottom: 0;
  }
  .mb-xs-8 {
    margin-bottom: 8px;
  }
  .mb-xs-16 {
    margin-bottom: 16px;
  }
  .mb-xs-24 {
    margin-bottom: 24px;
  }
  .mb-xs-32 {
    margin-bottom: 32px;
  }
  .mb-xs-40 {
    margin-bottom: 40px;
  }
  .mb-xs-48 {
    margin-bottom: 48px;
  }
  .mb-xs-64 {
    margin-bottom: 64px;
  }
  .mb-xs-72 {
    margin-bottom: 72px;
  }
  .mb-xs-80 {
    margin-bottom: 80px;
  }
  .mb-xs-88 {
    margin-bottom: 88px;
  }
  .mb-xs-96 {
    margin-bottom: 96px;
  }
  .mb-xs-104 {
    margin-bottom: 104px;
  }
  .mb-xs-112 {
    margin-bottom: 112px;
  }
  .mb-xs-120 {
    margin-bottom: 120px;
  }
  .mt-xs-0 {
    margin-top: 0 !important;
  }
  .mt-xs-8 {
    margin-top: 8px;
  }
  .mt-xs-16 {
    margin-top: 16px;
  }
  .mt-xs-24 {
    margin-top: 24px;
  }
  .mt-xs-32 {
    margin-top: 32px;
  }
  .mt-xs-40 {
    margin-top: 40px;
  }
  .mt-xs-48 {
    margin-top: 48px;
  }
  .mt-xs-56 {
    margin-top: 56px;
  }
  .mt-xs-64 {
    margin-top: 64px;
  }
  .mt-xs-72 {
    margin-top: 72px;
  }
  .mt-xs-80 {
    margin-top: 80px;
  }
  .mt-xs-88 {
    margin-top: 88px;
  }
  .mt-xs-96 {
    margin-top: 96px;
  }
  .mt-xs-104 {
    margin-top: 104px;
  }
  .mt-xs-112 {
    margin-top: 112px;
  }
  .mt-xs-120 {
    margin-top: 120px;
  }
  .p0-xs {
    padding: 0;
  }
  .pt-xs-0 {
    padding-top: 0;
  }
  .pt-xs-8 {
    padding-top: 8px;
  }
  .pt-xs-16 {
    padding-top: 16px;
  }
  .pt-xs-24 {
    padding-top: 24px;
  }
  .pt-xs-32 {
    padding-top: 32px;
  }
  .pt-xs-40 {
    padding-top: 40px;
  }
  .pt-xs-48 {
    padding-top: 48px;
  }
  .pt-xs-64 {
    padding-top: 64px;
  }
  .pt-xs-72 {
    padding-top: 72px;
  }
  .pt-xs-80 {
    padding-top: 80px;
  }
  .pt-xs-88 {
    padding-top: 88px;
  }
  .pt-xs-96 {
    padding-top: 96px;
  }
  .pt-xs-104 {
    padding-top: 104px;
  }
  .pt-xs-112 {
    padding-top: 112px;
  }
  .pt-xs-120 {
    padding-top: 120px;
  }
  .pt-xs-160 {
    padding-top: 160px;
  }
  .pt-xs-180 {
    padding-top: 180px;
  }
  .pt-xs-240 {
    padding-top: 240px !important;
  }
  .pb-xs-0 {
    padding-bottom: 0;
  }
  .pb-xs-8 {
    padding-bottom: 8px;
  }
  .pb-xs-16 {
    padding-bottom: 16px;
  }
  .pb-xs-24 {
    padding-bottom: 24px;
  }
  .pb-xs-32 {
    padding-bottom: 32px;
  }
  .pb-xs-40 {
    padding-bottom: 40px;
  }
  .pb-xs-48 {
    padding-bottom: 48px;
  }
  .pb-xs-64 {
    padding-bottom: 64px;
  }
  .pb-xs-72 {
    padding-bottom: 72px;
  }
  .pb-xs-80 {
    padding-bottom: 80px;
  }
  .pb-xs-88 {
    padding-bottom: 88px;
  }
  .pb-xs-96 {
    padding-bottom: 96px;
  }
  .pb-xs-104 {
    padding-bottom: 104px;
  }
  .pb-xs-112 {
    padding-bottom: 112px;
  }
  .pb-xs-120 {
    padding-bottom: 120px;
  }
  .pb-xs-180 {
    padding-bottom: 180px;
  }
  .pb-xs-240 {
    padding-bottom: 240px !important;
  }
}
