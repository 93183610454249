/****** PLACE YOUR CUSTOM STYLES HERE ******/
/*
.bg-dark {
background-color: #fb6603 !important;
}
*/
/*
.nav-bar {
background-color: #000 !important;
}
*/
.bg-orange {
  background-color: #fb6603 !important;
}

.fold1 {
  background-color: #f6f4f4 !important;
}

.fold2 {
  background-color: #fb6603 !important;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.color-primary {
  color: #fb6603 !important;
}

.white-on-orange {
  color: #fff !important;
}

.col-copyright {
  width: 100%;
  text-align: center;
}

.footer-1 .logo {
  max-height: 36px;
}

footer.bg-dark a {
  color: #fff;
  opacity: 1 !important;
}

footer.bg-dark a:hover {
  opacity: 1 !important;
}

.image-small {
  max-height: 65px !important;
}

.btn {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 2px solid #fff;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  color: #47b475;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  margin-right: 8px;
  margin-bottom: 24px;
}

.btn:last-child,
.btn:last-of-type {
  margin-right: 0;
}

.btn:hover {
  background: #ff9c00;
  border-color: white;
}

.btn-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  min-width: 0;
  padding: 0;
  line-height: 38px;
}

.btn-lg {
  height: 50px;
  line-height: 46px;
  min-width: 200px;
}

.btn-icon.btn-lg {
  width: 50px;
  height: 50px;
  line-height: 49px;
  font-size: 24px;
  min-width: 0;
}

.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
  line-height: 29px;
  font-size: 13px;
  min-width: 0;
  padding: 0 0 0 1px !important;
}

.btn-sm {
  height: 30px;
  font-size: 11px;
  line-height: 27px;
  min-width: 0;
}

.btn-filled {
  background: #fb6603;
  color: #fff;
}

.btn-white,
.image-bg .btn,
.image-bg .btn:visited {
  color: #fff;
  border-color: #fff;
}

.btn-white:hover,
.image-bg .btn:hover,
.image-bg .btn:visited:hover {
  background: #fff;
  color: #222;
}

#images {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

@media screen and (max-width: 768px) {
  .btn-white {
    border: none;
    opacity: 0.5;
    font-size: 15px;
    text-align: right;
    padding-right: 10px;
    font-weight: 600;
  }

  .btn-white:hover {
    background: none;
    color: white;
    opacity: 1;
  }

  .h4 {
    font-size: 15px;
    padding: 2px;
  }

  /* .logo {
    max-height: 20px !important;
  } */

  button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 426px) {
  h1.customized {
    font-size: 28px;
  }
}

@media screen and (max-width: 376px) {
  h1.customized {
    font-size: 22px;
  }
}

@media screen and (max-width: 320px) {
  h1.uppercase {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 4px;
  }
}